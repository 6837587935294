<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="2"></topMenu>
    </div>
    <div class="main-body" style="flex-direction: column">
        <div style="color: #832C75;font-weight: bold;font-size: 26px;margin-bottom: 40px">请选择类型</div>
        <div style="display: flex">
          <div class="age-box" style="cursor: pointer" @click="goComplete(examinerNum.wisc_youth_complete_num)">
            <img src="/images/select-age1.png" alt="" style="width: 450px">
<!--            <div class="num-box">-->
<!--              <div class="num-item" @click="goInfantTeachers(examinerNum.child_teacher_num)">-->
<!--                <span class="num">{{examinerNum.child_teacher_num}}份</span>-->
<!--                <span class="name">教师</span>-->
<!--              </div>-->
<!--              <div class="line"></div>-->
<!--              <div class="num-item" @click="goInfantParents(examinerNum.child_parent_num)">-->
<!--                <span class="num">{{examinerNum.child_parent_num}}份</span>-->
<!--                <span class="name">家长</span>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="age-box" style="cursor: pointer" @click="goSimple(examinerNum.wisc_youth_simple_num)">
            <img src="/images/select-age2.png" alt="" style="width: 450px">
<!--            <div class="num-box">-->
<!--              <div class="num-item" @click="goChildrenTeachers(examinerNum.youth_teacher_num)">-->
<!--                <span class="num">{{examinerNum.youth_teacher_num}}份</span>-->
<!--                <span class="name">教师</span>-->
<!--              </div>-->
<!--              <div class="line"></div>-->
<!--              <div class="num-item" @click="goChildrenParents(examinerNum.youth_parent_num)">-->
<!--                <span class="num">{{examinerNum.youth_parent_num}}份</span>-->
<!--                <span class="name">家长</span>-->
<!--              </div>-->
<!--            </div>-->
          </div>
<!--          <div class="age-box" style="cursor: pointer" @click="goJuvenile(examinerNum.wisc_youth_num)">-->
<!--            <img src="/images/age-3.png" alt=""/>-->
<!--          </div>-->
        </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import * as Api from './api';
import {GetInfo} from '../../commonApi';
import {message} from "ant-design-vue";

export default {
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 18},
      examinerNum: {
        "wisc_youth_simple_num": 0,
        "wisc_youth_complete_num": 0
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    goLogin(n) {

      this.$router.push({path: '/login'})
    },
    goActivation(n) {

      this.$router.push({path: '/activation'})
    },
    goComplete(n) {
      if (n == 0) {
        message.error({ content: '可使用次数不足，请联系客服人员充值'});
        return;
      }
      this.$router.push({path: '/complete'})
    },
    goSimple(n) {
      if (n == 0) {
        message.error({ content: '可使用次数不足，请联系客服人员充值'});
        return;
      }
      this.$router.push({path: '/simple'})
    },

    async getInfo() {
      const res = await GetInfo({});
      if (res && res.code == 0) {
        this.examinerNum = res.examinerNum;
      }
    },

  }
};
</script>

<style scoped>
.num-box {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 30px;
}
.num-box .line {
  height: 45px;
  border-right: 1px solid #cc8b7b;
  margin-top: 5px;
}
.num-box .num-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.num-box .num-item .num {
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
}
.num-box .num-item .name {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.5;
}
.main-body {
  background-image: url("/images/forget-password-bg.png");
  align-items: center;
  justify-content: center;
}
.age-box {
  position: relative;
  display: flex;
}
.age-box img {
  width: 350px;
  border-radius: 12px;
}
.age-box:nth-child(2) {
  margin: 0 60px;
}



</style>
